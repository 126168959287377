window.onload = function () {
  var videoElement = document.getElementById('first-screen-video');
  var otherVideos = document.querySelectorAll('video:not(#first-screen-video)');

  function playVideo(video) {
      video.play().catch(function (error) {
          console.error('Autoplay failed:', error);

          var retryInterval = setInterval(function () {
              video.play().then(() => {
                  clearInterval(retryInterval);
              }).catch(() => {
                  console.warn('Retrying video playback...');
              });
          }, 500);
      });
  }

  if (videoElement) {
      var source = document.createElement('source');
      source.src = window.innerWidth <= 450
          ? '/assets/home/first-screen-video-new-mobile.mp4'
          : '/assets/home/first-screen-video-new.mp4';
      source.type = 'video/mp4';
      videoElement.appendChild(source);

      videoElement.muted = true;
      videoElement.playsInline = true;
      videoElement.load();

      videoElement.addEventListener('canplay', function () {
          playVideo(videoElement);
      });

      document.addEventListener('WeixinJSBridgeReady', function () {
          playVideo(videoElement);
      });
  }

  if (otherVideos) {
      otherVideos.forEach(function (otherVideo) {
          otherVideo.muted = true;
          otherVideo.playsInline = true;
          otherVideo.load();

          otherVideo.addEventListener('canplay', function () {
              playVideo(otherVideo);
          });

          document.addEventListener('WeixinJSBridgeReady', function () {
              playVideo(otherVideo);
          });
      });
  }
};
